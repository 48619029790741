import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import clsx from 'clsx';
import { filter, isEmpty, map, omit } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  STAGE_STATUS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules, titleCase } from '../../common/utils';
import CommonDropdown from '../../components/CommonDropdown';
import CommonSelect from '../../components/CommonSelect';
import { UPDATE_APPROVAL_STATUS } from './graphql/Mutation';
import {
  PROJECT_AGENCY_CONTACT_LIST,
  PROJECT_EQC_TYPE_AGENCY_LIST,
  PROJECT_INSPECTOR_LIST
} from './graphql/Queries';

const { Option } = CommonSelect;
const { required } = formValidatorRules;

const ApproveModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    title,
    status,
    commentData,
    additionalComment
  } = props;
  const { token } = useParams();
  const [form] = Form.useForm();
  const [showApproverModal, setShowApproverModal] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [agencyId, setAgencyId] = useState();
  const history = useHistory();
  const handleCancel = () => {
    setShowModal(false);
  };

  const approversData = filter(
    data?.getEqcStageHistoryWithoutAuth?.projectEqcTypeStage
      ?.projectEqcTypeStageApprovals,
    (record) =>
      record?.levelNumber ===
      data?.getEqcStageHistoryWithoutAuth?.levelNumber + 1
  );
  const [updateApprovalStatus, { loading }] = useMutation(
    UPDATE_APPROVAL_STATUS,
    {
      onError() {},
      onCompleted() {
        setShowModal(false);
        Event(GA_EVENT.APPROVE_EQC, {
          label: GA_LABEL.APPROVE_EQC,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          status
        });
        history.push(ROUTES.EQC, {
          showThankyouModal: true
        });
      }
    }
  );

  const updateStatus = (value) => {
    let mutationData = {
      stageHistoryId: Number(data?.getEqcStageHistoryWithoutAuth?.id),
      status: status,
      commentData
    };
    if (!isSelectedAll && selectedApprovers?.length > 0) {
      mutationData.approverNotifyData = map(selectedApprovers, (approver) => ({
        id: Number(approver?.value),
        type: approver?.['data-type']
      }));
    }
    if (isSelectedAll) {
      mutationData.approverNotifyData = map(approversData, (approver) => ({
        id: Number(
          approver?.externalApproverId || approver?.internalApproverId
        ),
        type: approver?.externalApproverId ? 'external' : 'internal'
      }));
    }

    if (additionalComment?.remark) {
      mutationData.additionalComment = additionalComment;
    }
    if (isEmpty(commentData?.eqcStage)) {
      mutationData.commentData = omit(mutationData.commentData, ['eqcStage']);
    }
    if (isEmpty(commentData?.eqcStageItems)) {
      mutationData.commentData = omit(mutationData.commentData, [
        'eqcStageItems'
      ]);
    }
    if (value) {
      mutationData = {
        ...mutationData,
        ...(data?.getEqcStageHistoryWithoutAuth?.isRfiStage ? value : {})
      };
    }

    updateApprovalStatus({
      variables: {
        data: {
          ...mutationData,
          token
        }
      }
    });
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        closable={false}
        footer={null}
        width={350}
        destroyOnClose
        wrapClassName="approval-confirm-modal"
      >
        <div className="title">
          {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
            ? `${title} RFI`
            : `${title} EQC STAGE`}
        </div>
        <div className="description mb-16">
          {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
            ? `Are you sure you want to ${title.toLowerCase()} this RFI?`
            : `Are you sure you want to ${title.toLowerCase()} this EQC Stage?`}
        </div>
        <div>
          <Button shape="round" className="mr-10" onClick={handleCancel}>
            No
          </Button>
          <Button
            shape="round"
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              if (
                (approversData?.length > 0 &&
                  status === STAGE_STATUS.APPROVED) ||
                data?.getEqcStageHistoryWithoutAuth?.isRfiStage ||
                status === STAGE_STATUS.REJECTED
              ) {
                setShowApproverModal(true);
                setShowModal(false);
              } else {
                updateStatus();
              }
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        centered
        visible={showApproverModal}
        closable={false}
        footer={null}
        destroyOnClose
        wrapClassName="approval-selection-modal"
      >
        <Form
          form={form}
          layout="vertical"
          className="stage-config"
          onFinish={updateStatus}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            data?.getEqcStageHistoryWithoutAuth?.isRfiStage ? (
              status === STAGE_STATUS.ACCEPTED ? (
                <Form.Item
                  name="projectInspectorIds"
                  label={
                    <div className="d-flex justify-between width-percent-100">
                      <span>Select inspector you want to notify</span>
                    </div>
                  }
                  rules={[required]}
                >
                  <CommonDropdown
                    mode="multiple"
                    placeholder="Select Inspector"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    query={PROJECT_INSPECTOR_LIST}
                    responsePath="projectInspectorList.data"
                    valuePath="id"
                    labelPath="name"
                    fetchPolicy="network-only"
                    variables={{
                      filter: {
                        projectId: Number(
                          data?.getEqcStageHistoryWithoutAuth?.projectId
                        ),
                        projectEqcTypeId: Number(
                          data?.getEqcStageHistoryWithoutAuth?.eqc
                            ?.projectEqcTypeId
                        )
                      }
                    }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    name="projectAgencyId"
                    label={
                      <div className="d-flex justify-between width-percent-100">
                        <span>Select Agency</span>
                      </div>
                    }
                    rules={[required]}
                  >
                    <CommonDropdown
                      allowClear
                      placeholder="Select Agency"
                      className={clsx(
                        `instruction-selector dropdown-width-auto width-percent-100`
                      )}
                      onChange={(id) => {
                        setAgencyId(id);
                        form.setFieldsValue({ contactIds: [] });
                      }}
                      value={agencyId}
                      query={PROJECT_EQC_TYPE_AGENCY_LIST}
                      variables={{
                        filter: {
                          projectId: Number(
                            data?.getEqcStageHistoryWithoutAuth?.projectId
                          ),
                          projectEqcTypeId: Number(
                            data?.getEqcStageHistoryWithoutAuth?.eqc
                              ?.projectEqcTypeId
                          )
                        }
                      }}
                      fetchPolicy="network-only"
                      responsePath="projectEqcTypeAgencyList.data"
                      valuePath="id"
                      labelPath="agency.name"
                      showSearch
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="contactIds"
                    label={
                      <div className="d-flex justify-between width-percent-100">
                        <span>Select Agency Contact</span>
                      </div>
                    }
                    rules={[required]}
                  >
                    <CommonDropdown
                      allowClear
                      showSearch
                      mode="multiple"
                      placeholder="Select Contact"
                      optionFilterProp="children"
                      className={clsx(
                        `instruction-selector dropdown-width-auto width-percent-100`,
                        !agencyId && 'pointerEvents-none'
                      )}
                      disabled={!agencyId}
                      query={PROJECT_AGENCY_CONTACT_LIST}
                      variables={{
                        filter: {
                          projectId: Number(
                            data?.getEqcStageHistoryWithoutAuth?.projectId
                          ),
                          projectAgencyId: Number(agencyId)
                        }
                      }}
                      fetchPolicy="network-only"
                      responsePath="projectAgencyContactList.data"
                      valuePath="id"
                      labelPath="name"
                      useEffectDeps={[agencyId]}
                      conditionToCheckBeforeQuery={!!agencyId}
                      projectId={agencyId}
                    />
                  </Form.Item>
                </>
              )
            ) : (
              <Form.Item
                rules={[required]}
                name="approvers"
                label={
                  <div className="d-flex justify-between width-percent-100">
                    <span>Select approvers you want to notify</span>
                    {isSelectedAll && (
                      <span
                        className="text-primary pointer"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setIsSelectedAll(false);
                          form.setFieldsValue({
                            approvers: []
                          });
                        }}
                      >
                        Deselect All
                      </span>
                    )}
                  </div>
                }
                normalize={(value) => {
                  if (value.includes('all')) {
                    setIsSelectedAll(true);
                    return ['all'];
                  }
                  return value;
                }}
              >
                <CommonSelect
                  mode="multiple"
                  disabled={isSelectedAll}
                  className="mr-3"
                  placeholder="Select Approvers"
                  allowClear
                  onChange={(_, record) => {
                    setSelectedApprovers(record);
                  }}
                  optionFilterProp="children"
                >
                  <Option key="approver-select-all" value="all">
                    Select All
                  </Option>
                  {approversData.map((record) => (
                    <Option
                      key={
                        record?.externalApproverId || record?.internalApproverId
                      }
                      value={
                        record?.externalApproverId || record?.internalApproverId
                      }
                      data-type={
                        record?.externalApproverId ? 'external' : 'internal'
                      }
                      label={titleCase(
                        record?.externalApprover?.name ||
                          record?.internalApprover?.user?.name
                      )}
                    >
                      {titleCase(
                        record?.externalApprover?.name ||
                          record?.internalApprover?.user?.name
                      )}
                    </Option>
                  ))}
                </CommonSelect>
              </Form.Item>
            )
          }
          <Form.Item>
            <div className="d-flex justify-end">
              <Button
                shape="round"
                className="mr-10"
                onClick={() => setShowApproverModal(false)}
              >
                Cancel
              </Button>
              <Button
                shape="round"
                htmlType="submit"
                type="primary"
                loading={loading}
              >
                Done
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveModal;
